import styled from 'styled-components';
import { textSizes } from '../../../assets/theme';

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.foreground};
  border: none;
  padding: 8px 16px;
  font-size: ${textSizes.md}px;

  ${(props) => props.hidden ? `
    height: 0;
    width: 0;
    opacity: 0;
  ` : ''}
`;
