import React from 'react';
import { StyledInput } from './styles';

const Input = ({ hidden, ...props }) => (
  <StyledInput hidden={hidden} {...props} />
);

Input.propTypes = {

};

export default Input;
