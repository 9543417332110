import React from 'react';
import { number, string } from 'prop-types';

const OpenCollective = ({ size = '24', ...rest }) => (
  <svg viewBox="0 0 32 32" fill="none" height={size} width={size} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.1531 6.8877C30.948 9.47379 31.9999 12.614 31.9999 16.0003C31.9999 19.3866 30.948 22.5271 29.1531 25.1129L25.0085 20.9684C25.8225 19.4957 26.2858 17.8019 26.2858 16.0003C26.2858 14.1987 25.8225 12.5052 25.0085 11.0325L29.1531 6.8877Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1126 2.84685L20.9678 6.99138C19.4951 6.17745 17.8016 5.71417 16 5.71417C10.3194 5.71417 5.71418 10.3194 5.71418 16C5.71418 21.6806 10.3194 26.2858 16 26.2858C17.8016 26.2858 19.4951 25.8226 20.9678 25.0086L25.1126 29.1532C22.5265 30.948 19.3863 32 16 32C7.16352 32 0 24.8365 0 16C0 7.16351 7.16352 0 16 0C19.3863 0 22.5265 1.05197 25.1126 2.84685Z"
    />
  </svg>
);

OpenCollective.propTypes = {
  size: number,
  colour: string,
};

export default OpenCollective;
