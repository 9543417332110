import styled from 'styled-components';
import { H2, Label } from '../../atoms/Text';

export const Form = styled.form`
  /* border: 1px solid white; */
  /* padding: 12px 16px; */
`;

export const InputGroup = styled.div`
  border: 2px solid ${props => props.theme.foreground};
  display: flex;
  justify-content: stretch;
  margin-bottom: 8px;
  max-width: 500px;

  input {
    flex: 1;
  }
`;

export const SubmitButton = styled.button`
  background-color: ${props => props.theme.foreground};
  color: ${props => props.theme.background};
  display: flex;
  height: 35px;
  width: 35px;
  border: none;
  cursor: none;
  border: 1px solid ${props => props.theme.background};

  span {
    position: relative;
    top: 1px;
    right: 1px;
    color: ${props => props.theme.background};
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
  `}
`;

export const Title = styled(H2)`
  margin: 16px 0;
`;

export const ErrorMessage = styled(Label)`
  margin: 12px 17px;
  display: block;
`;
