/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { colours, textSizes } from '../../../assets/theme';

import { useLangStore } from '../../../store';
import { useTranslation } from '../../../hooks/TranslationHooks';
import { useToggle } from '../../../hooks/utility';
import Input from '../../atoms/Input';
import { Span } from '../../atoms/Text';
import { ErrorMessage, Form, InputGroup, SubmitButton, Title } from './styles';
import Checkbox from '../../atoms/Checkbox';
import { regex } from '../../../helpers/regex';
import config from '../../../../config';
import { useInterval } from 'react-use';
import { seconds } from '../../../helpers/time';

const { email: emailRegex } = regex;

const NewsletterForm = props => {
  const [email, setEmail] = useState('');
  const [newsEmails, toggleNews] = useToggle(false);
  const [progEmails, toggleProg] = useToggle(false);
  const [formHasError, setFormHasError] = useState({ status: true, label: '' });
  const [emailHasError, setEmailHasError] = useState({ status: true, label: '' });
  const [submitError, setSubmitError] = useState({ status: false, label: '' });
  const [submitTimer, updateSubmitTimer] = useState(null);

  const lang = useLangStore(store => store.currentLang);
  const subscribeLabel = useTranslation({ key: 'subscribe', lang });
  const subscribeForProgLabel = useTranslation({ key: 'subscribeForProgram', lang });
  const subscribeForNewsletterLabel = useTranslation({ key: 'subscribeForNewsletter', lang });
  const emailAddressPlaceholder = useTranslation({ key: 'emailAddress', lang });

  useInterval(() => {
    if (submitTimer < 1) {
      updateSubmitTimer(null);
    } else {
      updateSubmitTimer(submitTimer - 1);
    }
  }, submitTimer ? seconds(1) : null);

  useEffect(() => {
    if (!newsEmails && !progEmails) {
      setFormHasError({ status: true, label: 'Please select at least one type of email to receive' });
    } else {
      setFormHasError({ status: false });
    }
  }, [newsEmails, progEmails]);

  useEffect(() => {
    if (!email) {
      setEmailHasError({
        status: true,
        label: '',
      });
    }
    if (emailRegex.test(email)) {
      setEmailHasError({
        status: false,
        label: '',
      });
    } else {
      setEmailHasError({
        status: true,
        label: '',
      });
    }
  }, [email]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validate = () => {
    if (email && !emailRegex.test(email)) {
      setEmailHasError({
        status: true, label: 'Enter a valid email address',
      });
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    updateSubmitTimer(70);
    if (!formHasError.status && !emailHasError.status && (newsEmails || progEmails)) {
      try {
        const httpReq = await axios.post(`${config.cmsUrl}/subscribers/subscribe`, {
          email,
          lang: lang || 'en',
          newsletter: newsEmails,
          programEmails: progEmails,
        });

        if (httpReq.status === 200) {
          setSubmitError({ status: false, label: 'Sent. Check your email inbox!' });
        }
      } catch (error) {
        setSubmitError({ status: true, label: 'Hmm there was a problem sending your request...' });
      }
    }
  };

  return (
    <Form {...props} className="newsletter-form" onSubmit={submit}>
      <Title size={textSizes.xl}>{subscribeLabel}</Title>
      <InputGroup>
        <Input type="email" name="email" placeholder={emailAddressPlaceholder} value={email} onChange={handleEmailChange} onBlur={validate} />
        <Input type="text" name="name" placeholder="Name" hidden value="x190" />
        <SubmitButton data-hoverable={!emailHasError.status && !formHasError.status && !submitTimer} disabled={emailHasError.status || formHasError.status || submitTimer}><Span size="32" colour={colours.black}>→</Span></SubmitButton>
      </InputGroup>
      {submitError.label && <ErrorMessage size={textSizes.sm} colour={submitError.status ? colours.red : colours.green}>{submitError.label}</ErrorMessage>}
      {email && emailHasError.status && emailHasError.label && <ErrorMessage size={textSizes.sm} colour={colours.red}>{emailHasError.label}</ErrorMessage>}
      <Checkbox checked={newsEmails} onChange={toggleNews} label={subscribeForNewsletterLabel} />
      <Checkbox checked={progEmails} onChange={toggleProg} label={subscribeForProgLabel} />
      {email && !emailHasError.status && formHasError.status && formHasError.label && <ErrorMessage size={textSizes.sm} colour={colours.red}>{formHasError.label}</ErrorMessage>}
    </Form>
  );
};

NewsletterForm.propTypes = {};

export default NewsletterForm;
