import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Page from '../../organisms/Page';
import ContentsWrapper from '../../Layout/ContentsWrapper';
import { Markdown, Fade, Columns, TextBlock, StyledTagLink as LinkTag, BoxLink } from './styles';
import { useLangStore } from '../../../store';
import NewsletterForm from '../../molecules/NewsletterForm';
import OpenCollectiveSvg from '../../icons/OpenCollective';
import { Span } from '../../atoms/Text';

const AboutPage = pageData => {
  const currentLang = useLangStore(store => store.currentLang);

  const {
    strapiAboutPage,
    strapiAboutPage: { contact_links: contactLinks },
  } = useStaticQuery(graphql`
    query AboutPageQuery {
      strapiAboutPage {
        about_text_en
        about_text_right_en
        about_text_fr
        about_text_right_fr
        open_collective_url
        support_us_en
        support_us_fr
        bankDetails
        contact_links {
          label
          url
        }

        page_meta {
          page_title_fr
          page_title_en
        }
      }
    }
  `);

  return (
    <Page
      title={{ en: strapiAboutPage?.page_meta?.page_title_en, fr: strapiAboutPage?.page_meta?.page_title_fr }}
      data={pageData}
    >
      <ContentsWrapper
        css={`
          position: relative;
        `}
      >
        <Columns>
          <Markdown>{strapiAboutPage[`about_text_${currentLang}`]}</Markdown>
          <div>
            <Markdown>{strapiAboutPage[`about_text_right_${currentLang}`]}</Markdown>
            <LinkTag
              renderIcon={OpenCollectiveSvg}
              href={strapiAboutPage.open_collective_url}
              label={strapiAboutPage[`support_us_${currentLang}`]}
              size="lg"
              newtab
            />
            <Markdown>## Contact</Markdown>
            {contactLinks.map(link => (
              <LinkTag href={link.url} label={link.label} size="lg" newtab />
            ))}
            <NewsletterForm />
          </div>
        </Columns>

        <Fade />
      </ContentsWrapper>
    </Page>
  );
};

export default AboutPage;
